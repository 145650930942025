import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-pro';
import useMyDropdowns from 'hooks/useMyDropdowns';
import useMyRole from 'hooks/useMyRole';
import useMyMenuItems from 'hooks/useMyMenuItems';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { useSelector } from 'store';
import { StripedDataGrid } from 'views/backoffice/CustomLists/components';
import { IUser } from 'views/backoffice/users/types';
import { FileViewer } from './FileViewer';
import { AttachmentRow } from '../types';
import { getListColumns } from '../assets';
import { S3ImgUrlGetter } from 'utils/S3Uploader';

export type ListProps = {
    rows: AttachmentRow[];
    onDeleteRow: (row: AttachmentRow) => Promise<void>;
    onUpdateRow: (row: AttachmentRow) => Promise<void>;
};

export const List = ({ rows, onDeleteRow, onUpdateRow }: ListProps) => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const recordTypeId = queryParams.get('recordType') ?? '';

    const modal = useConfirmationModalContext();
    const { loading: loadingDropdown, usersList } = useMyDropdowns({ dropdowns: ['USER_LIST'] });
    const [selectedRow, setSelectedRow] = useState<AttachmentRow | null>(null);
    const [fileSignedUrl, setFileSignedUrl] = useState('');
    const [isOpenPreview, setIsOpenPreview] = useState(false);
    const userListById = usersList.reduce((acc: Record<number, IUser>, user) => ({ ...acc, [Number(user.id)]: user }), {});
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const { actionData } = useSelector((store) => store.menu);
    const { menuItemList } = useMyMenuItems();
    const roleId = useMyRole();

    const isAllowedDelete = useMemo(() => {
        const menuItemForRecordType = menuItemList?.find((item) => item.recordType?.id && +item.recordType.id === +recordTypeId);

        const actionForMenuItem = actionData.find(
            (el) => Number(el.menuItem.id) === Number(menuItemForRecordType?.id) && Number(el.role.id) === (roleId as number)
        );

        const haveAccess = actionForMenuItem?.menuItemActions.find((el) => el.name.toLowerCase().includes('record soft delete'))?.enabled;

        return haveAccess;
    }, [actionData, menuItemList, recordTypeId, roleId]);

    const onOpenFile = async (row: AttachmentRow) => {
        setSelectedRow(row);

        const url = row.fileType === 'application/pdf' ? await S3ImgUrlGetter(row.fileUrl) : '';
        setFileSignedUrl(url);

        setIsOpenPreview(true);
    };
    const handleClosePreview = () => {
        setIsOpenPreview(false);
        setSelectedRow(null);
    };

    const onDownloadFile = async (row: AttachmentRow | null) => {
        if (!row) return;
        const url = await S3ImgUrlGetter(row.fileUrl);
        const link = document.createElement('a');

        link.href = url;
        link.download = row?.name;
        link.click();
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const onClickEdit = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const onClickSave = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const onClickCancel = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true }
        });
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = { ...newRow };
        await onUpdateRow(updatedRow as AttachmentRow);
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const onDeleteFile = async (row: AttachmentRow | null = selectedRow) => {
        if (!row) return;
        await modal.showConfirmation({
            title: 'Delete Attachment',
            content: `Are you sure you want to delete this attachment?`,
            loadingText: 'Deleting',
            forwardButtonText: 'Yes',
            actionButtonText: 'No',
            onForward: async () => {
                setSelectedRow(null);
                setIsOpenPreview(false);
                await onDeleteRow(row);
            },
            onAction: null,
            showCheckbox: false,
            actionType: 'delete',
            sectionTitle: 'record_viewer',
            recordType: 'attachments'
        });
    };

    return (
        <>
            <StripedDataGrid
                sx={{ height: '100%' }}
                loading={loadingDropdown}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                columns={getListColumns({
                    onOpenFile,
                    onDeleteFile,
                    onDownloadFile,
                    onClickEdit,
                    onClickSave,
                    onClickCancel,
                    rowModesModel,
                    isAllowedDelete,
                    userListById
                })}
                rows={rows}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                disableRowSelectionOnClick
            />
            <FileViewer
                open={isOpenPreview}
                fileRow={selectedRow}
                signedUrl={fileSignedUrl}
                handleClose={handleClosePreview}
                onDelete={isAllowedDelete ? onDeleteFile : undefined}
                onDownload={onDownloadFile}
            />
        </>
    );
};
